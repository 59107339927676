import { RichText } from '@msdyn365-commerce/core';

import { Event, StringExtensions } from '@msdyn365-commerce-modules/retail-actions';

import { DismissibleNotification, IClosableNotification, IDescriptiveNotification, ITitledNotification, NotificationEvents } from '../base';

/**
 * Represents a system notification data instance.
 * By default, all system notification should have a title, be closable, auto-dismissible.
 * You can specify some optional description text for the main message.
 */
export abstract class SystemNotification extends DismissibleNotification implements ITitledNotification, IDescriptiveNotification, IClosableNotification {
    /**
     * Aria label which should be used for the close button. Should be localized.
     * @see IClosableNotification
     */
    public readonly closeAriaLabel: string;

    /**
     * Specifies if the notification is closed or not. True if it's closed, false otherwise.
     * @remark Use only to get information about the 'close' event, dismissible notifications do not use this field.
     * @see IClosableNotification
     */
    public isClosed: boolean;

    /**
     * The text with the title of notification. The text should be localized.
     * @see ITitledNotification
     */
    public readonly title: RichText;

    /**
     * The lines of text which are displayed to user. The text should be localized. Empty by default.
     * @see IDescriptiveNotification
     */
    public readonly descriptionLines: RichText[];

    /**
     * Initializes system notification with the given data.
     * @param {string} title The text with the title of notification. The text should be localized.
     * @param {string} closeAriaLabel Aria label which should be used for the close button. Should be localized.
     * @param {(string | undefined)[]} [descriptionLines] Description for the notification. Optional parameter.
     * The data will be filtered to include only non-null/non-whitespace text.
     */
    public constructor(title: RichText, closeAriaLabel: string, descriptionLines?: (RichText | undefined)[]) {
        super();

        this.title = title;
        this.closeAriaLabel = closeAriaLabel;
        this.descriptionLines = <RichText[]>(descriptionLines ?? []).filter(value => !StringExtensions.isNullOrWhitespace(value?.toString()));
        this.isClosed = false;

        this.events.setValue(NotificationEvents.Close, new Event());
    }

    /**
     * Triggers close event and marks that the notification is closed now.
     * Implementation should modify the `isClosed` field first and then trigger the `NotificationEvents.Close` event.
     * @see IClosableNotification
     */
    public close(): void {
        this.isClosed = true;
        this.events.getValue(NotificationEvents.Close)?.trigger();
    }
}