import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { Random } from '@msdyn365-commerce-modules/retail-actions';

import { BaseNotification, IClosableNotification } from '../../notifications-data-instances';
import { NotificationEvents } from '../../notifications-data-instances/base/events';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

/**
 * Represents state for closable wrapper.
 */
export interface IClosableNotificationWrapperState {
    isClosed: boolean;
}

/**
 * Wraps children into closable wrapper which hides the children (notification body) when the notification is closed.
 */
export class ClosableNotificationWrapper<NotificationType extends BaseNotification & IClosableNotification>
    extends BaseNotificationComponent<NotificationType, INotificationElementProps<NotificationType>, IClosableNotificationWrapperState> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-closable-notification';

    /**
     * Unique id of the component.
     */
    private readonly _instanceId: string;

    public constructor(props: INotificationElementProps<NotificationType>) {
        super(props);

        this._instanceId = `ClosableNotificationWrapper-${Random.Guid.generateGuid()}`;

        this.state = {
            isClosed: false
        };

        this._close = this._close.bind(this);
        this._unsubscribeFromEvents = this._unsubscribeFromEvents.bind(this);
    }

    public componentDidMount(): void {
        this._subscribeToEvents(this.props.notification);
    }

    public componentDidUpdate(previousProps: INotificationElementProps<NotificationType>): void {
        this._unsubscribeFromEvents(previousProps.notification);
        this._subscribeToEvents(this.props.notification);
    }

    public componentWillUnmount(): void {
        this._unsubscribeFromEvents(this.props.notification);
    }

    public render(): ReactNode {
        let className = ClosableNotificationWrapper.className;
        if (this.state.isClosed) {
            className = classnames(className, `${ClosableNotificationWrapper.className}__closed`);
        }

        return (
            <div className={className}>
                {this.props.children}
            </div>
        );
    }

    private _subscribeToEvents(notification: NotificationType): void {
        notification.events.getValue(NotificationEvents.Close)!.subscribe({
            instanceId: this._instanceId,
            handler: this._close
        });
        notification.events.getValue(NotificationEvents.RemovedFromList)!.subscribe({
            instanceId: this._instanceId,
            handler: () => this._unsubscribeFromEvents(notification)
        });
    }

    private _unsubscribeFromEvents(notification: NotificationType): void {
        notification.unsubscribeFromEvents(this._instanceId);
    }

    private _close(): void {
        this._unsubscribeFromEvents(this.props.notification);

        this.setState({ isClosed: this.props.notification.isClosed });

        // Wait for the animation before removing completely.
        setTimeout(() => {
            this.props.notification.remove();
        }, 100); // tslint:disable-line:align
    }
}