import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { ObjectExtensions, Random } from '@msdyn365-commerce-modules/retail-actions';

import { DismissibleNotification, NotificationEvents } from '../../notifications-data-instances';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

export interface IDismissibleNotificationWrapperState {
    isDismissing: boolean;
    isDismissed: boolean;
}

/**
 * Wrapper over notification body which allows dismissing of the notification.
 * It setups timeouts and hides the notification body when the notification should be dismissed.
 */
export class DismissibleNotificationWrapper<NotificationType extends DismissibleNotification>
    extends BaseNotificationComponent<NotificationType, INotificationElementProps<NotificationType>, IDismissibleNotificationWrapperState> {

    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-dismissible-notification';

    /**
     * Waiting for dismissing timer.
     */
    private _timer?: NodeJS.Timeout;

    /**
     * Unique id of the component.
     */
    private readonly _instanceId: string;

    public constructor(props: INotificationElementProps<NotificationType>) {
        super(props);

        this.state = {
            isDismissing: false,
            isDismissed: false
        };

        this._instanceId = `DismissibleNotificationWrapper-${Random.Guid.generateGuid()}`;

        this._setTimer = this._setTimer.bind(this);
        this._clearTimer = this._clearTimer.bind(this);
        this._startDismissing = this._startDismissing.bind(this);
        this._preventDismissing = this._preventDismissing.bind(this);
        this._preventDismissingHandler = this._preventDismissingHandler.bind(this);
        this._finishDismissing = this._finishDismissing.bind(this);
        this._updateState = this._updateState.bind(this);
        this._unsubscribeFromEvents = this._unsubscribeFromEvents.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.notification.autoDismissTimeout !== null) {
            this._setTimer();
        }
        this._subscribeToEvents(this.props.notification);
    }

    public componentDidUpdate(previousProps: INotificationElementProps<NotificationType>): void {
        this._unsubscribeFromEvents(previousProps.notification);
        this._subscribeToEvents(this.props.notification);
    }

    public componentWillUnmount(): void {
        this._unsubscribeFromEvents(this.props.notification);
    }

    public render(): ReactNode {
        let className = DismissibleNotificationWrapper.className;
        if (this.state.isDismissing) {
            className = classnames(className, `${DismissibleNotificationWrapper.className}__dismissing`);
        }
        if (this.state.isDismissed) {
            className = classnames(className, `${DismissibleNotificationWrapper.className}__dismissed`);
        }

        const onMouseEnter = this.props.notification.shouldResetOnHover ? this._preventDismissing : undefined;
        const onMouseLeave = this.props.notification.shouldResetOnHover ? this._setTimer : undefined;

        return (
            <div className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onAnimationEnd={this._finishDismissing}>
                {this.props.children}
            </div>
        );
    }

    private _subscribeToEvents(notification: NotificationType): void {
        notification.events.getValue(NotificationEvents.StartDismissing)!.subscribe({
            instanceId: this._instanceId,
            handler: this._updateState
        });
        notification.events.getValue(NotificationEvents.PreventDismissing)!.subscribe({
            instanceId: this._instanceId,
            handler: this._preventDismissingHandler
        });
        notification.events.getValue(NotificationEvents.FinishDismissing)!.subscribe({
            instanceId: this._instanceId,
            handler: () => this._updateState
        });
        notification.events.getValue(NotificationEvents.RemovedFromList)!.subscribe({
            instanceId: this._instanceId,
            handler: () => this._unsubscribeFromEvents(notification)
        });
    }

    private _unsubscribeFromEvents(notification: NotificationType): void {
        notification.unsubscribeFromEvents(this._instanceId);
    }

    private _setTimer(): void {
        this._timer = setTimeout(() => {
            this._startDismissing();
        }, this.props.notification.autoDismissTimeout!); // tslint:disable-line:align
    }

    private _clearTimer(): void {
        if (!ObjectExtensions.isNullOrUndefined(this._timer)) {
            clearTimeout(this._timer);
        }
    }

    private _startDismissing(): void {
        this.props.notification.startDismissing();
    }

    private _preventDismissingHandler(): void {
        this._clearTimer();
        this._updateState();
    }

    private _preventDismissing(): void {
        this.props.notification.preventDismissing();
    }

    private _finishDismissing(): void {
        this.props.notification.finishDismissing();
        this._updateState();
    }

    private _updateState(): void {
        this.setState({ isDismissing: this.props.notification.isDismissing, isDismissed: this.props.notification.isDismissed });
    }
}