import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import React from 'react';

import { SystemNotification } from '../../notifications-data-instances/system-notifications';
import { ClosableNotificationWrapper, DismissibleNotificationWrapper, INotificationCloseButtonProps, NotificationCloseButton, NotificationContent, NotificationDescription, NotificationHeader, NotificationInstance, NotificationTitle } from '../base';
import { BaseNotificationComponent, INotificationElementProps } from '../base/base-notification-component';

/**
 * Props for system notification.
 */
export interface ISystemNotificationProps extends INotificationElementProps<SystemNotification>, INotificationCloseButtonProps<SystemNotification> {
    /**
     * The class for the styles.
     */
    className: string;
}

/**
 * Component responsible for rendering a system notification.
 */
export class SystemNotificationComponent extends BaseNotificationComponent<SystemNotification, ISystemNotificationProps> {

    /**
     * Initializes the component with the given configuration.
     * @param {ISuccessNotificationProps} props Configuration of the component.
     */
    public constructor(props: ISystemNotificationProps) {
        super(props);
    }

    /**
     * Renders the given success notification.
     */
    public render(): React.ReactNode {
        return (
            <div className={this.props.className}>
                <ClosableNotificationWrapper
                    notification={this.props.notification}
                >
                    <DismissibleNotificationWrapper
                        notification={this.props.notification}
                    >
                        <NotificationInstance
                            notification={this.props.notification}
                        >
                            <NotificationHeader
                                notification={this.props.notification}
                            >
                                <NotificationTitle
                                    notification={this.props.notification}
                                />
                                <NotificationCloseButton
                                    notification={this.props.notification}
                                />
                            </NotificationHeader>
                            {
                                ArrayExtensions.hasElements(this.props.notification.descriptionLines) ? (
                                    <NotificationContent
                                        notification={this.props.notification}
                                    >
                                        <NotificationDescription
                                            notification={this.props.notification}
                                        />
                                    </NotificationContent>
                                ) : null
                            }
                        </NotificationInstance>
                    </DismissibleNotificationWrapper>
                </ClosableNotificationWrapper>
            </div>
        );
    }
}