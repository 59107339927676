import React, { ReactNode } from 'react';

import { BaseNotification } from '../../notifications-data-instances';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

/**
 * Displays header of the notification.
 * Usually header is used for displaying the title and close X button.
 */
export class NotificationHeader<NotificationType extends BaseNotification> extends BaseNotificationComponent<NotificationType, INotificationElementProps<NotificationType>> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-header';

    public constructor(props: INotificationElementProps<NotificationType>) {
        super(props);
    }

    public render(): ReactNode {
        return (
            <div className={NotificationHeader.className}>
                {this.props.children}
            </div>
        );
    }
}