import React from 'react';

import { SuccessNotification } from '../../notifications-data-instances/system-notifications';
import { INotificationCloseButtonProps } from '../base';
import { BaseNotificationComponent, INotificationProps } from '../base/base-notification-component';
import { SystemNotificationComponent } from './system-notification-component';

/**
 * Props for success notification.
 */
export interface ISuccessNotificationProps extends INotificationProps<SuccessNotification>, INotificationCloseButtonProps<SuccessNotification> {}

/**
 * Component responsible for rendering a success notification.
 */
export class SuccessNotificationComponent extends BaseNotificationComponent<SuccessNotification, ISuccessNotificationProps> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-success';

    /**
     * Initializes the component with the given configuration.
     * @param {ISuccessNotificationProps} props Configuration of the component.
     */
    public constructor(props: ISuccessNotificationProps) {
        super(props);
    }

    /**
     * Renders the given success notification.
     */
    public render(): React.ReactNode {
        return (
            <SystemNotificationComponent
                notification={this.props.notification}
                className={SuccessNotificationComponent.className}
            />
        );
    }
}