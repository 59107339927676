import { Dictionary } from '@msdyn365-commerce-modules/retail-actions';
import { NotificationFeeds, NotificationFeedType } from '../notifications-manager/notification-feeds';
import { INotificationComponentsList } from './base-notification-component-list';

/**
 * A notification list which is operated by notification manager.
 * it is responsible for rendering the received notifications.
 */
export class NotificationComponentsListsManager { // tslint:disable-line:no-unnecessary-class
    /**
     * Instances of the lists currently shown on the page.
     * Dictionary of lists by the feed.
     */
    private static _instances: Dictionary<NotificationFeedType, INotificationComponentsList[]> = new Dictionary();

    /**
     * Retrieve the lists which are displayed currently on the page for the given feed.
     * @param {NotificationFeeds} [feed] The feed to filter the lists. Global feed by default.
     */
    public static getNotificationLists(feed: NotificationFeeds): INotificationComponentsList[] {
        return NotificationComponentsListsManager._instances.getValueWithDefaultValue(feed, []);
    }

    /**
     * Registers notification components list to listen to notification manager.
     * @param {INotificationComponentsList} notificationComponentsList The component which renders the notifications.
     */
    public static subscribe(notificationComponentsList: INotificationComponentsList): void {
        NotificationComponentsListsManager._instances.getValueWithDefaultValue(notificationComponentsList.feed, []).push(notificationComponentsList);
    }

    /**
     * Removes the notification components list from the instances which listen to notification manager.
     * @param {INotificationComponentsList} notificationComponentsList The component which renders the notifications.
     */
    public static unsubscribe(notificationComponentsList: INotificationComponentsList): void {
        const instances = NotificationComponentsListsManager._instances.getValueWithDefaultValue(notificationComponentsList.feed, []);
        const newInstances = instances.filter(list => list !== notificationComponentsList);
        NotificationComponentsListsManager._instances.setValue(notificationComponentsList.feed, newInstances);
    }
}