import { RichText } from '@msdyn365-commerce/core';

import { StringExtensions } from '@msdyn365-commerce-modules/retail-actions';

import { LoadingNotificationComponent } from '../../notification-components/system-notifications/loading-notification-component';
import { DismissibleNotification, IDescriptiveNotification, ITitledNotification, withNotificationComponent } from '../base';

/**
 * Represents a loading notification.
 * Use this notification when a process takes some time to be completed.
 * This type of notification should be used to show the user that the flow is in process.
 * @remark Do not use the notification for quick actions.
 */
@withNotificationComponent(LoadingNotificationComponent)
export class LoadingNotification extends DismissibleNotification implements ITitledNotification, IDescriptiveNotification {
    /**
     * The text with the title of notification. The text should be localized.
     * @see ITitledNotification
     */
    public readonly title: RichText;

    /**
     * The lines of text which are displayed to user. The text should be localized. Empty by default.
     * @see IDescriptiveNotification
     */
    public readonly descriptionLines: RichText[];

    /**
     * Represents loading state when some flow is in progress.
     */
    public get isLoading(): boolean {
        return this._isLoading;
    }

    /**
     * Represents loading state when some flow is in progress.
     */
    private _isLoading: boolean;

    /**
     * Initializes system notification with the given data.
     * @param {string} title The text with the title of notification. The text should be localized.
     * @param {string} closeAriaLabel Aria label which should be used for the close button. Should be localized.
     * @param {(string | undefined)[]} [descriptionLines] Description for the notification. Optional parameter.
     * The data will be filtered to include only non-null/non-whitespace text.
     */
    public constructor(title: RichText, descriptionLines?: (RichText | undefined)[]) {
        super(null, false);

        this._isLoading = true;
        this.title = title;
        this.descriptionLines = <RichText[]>(descriptionLines ?? []).filter(value => !StringExtensions.isNullOrWhitespace(value?.toString()));
    }

    /**
     * Tells notification instance that the loading is completed.
     */
    public finishLoading(): void {
        this._isLoading = false;
        this.startDismissing();
    }
}