import React from 'react';

import { WarningNotification } from '../../notifications-data-instances/system-notifications/warning-notification';
import { INotificationCloseButtonProps } from '../base';
import { BaseNotificationComponent, INotificationProps } from '../base/base-notification-component';
import { SystemNotificationComponent } from './system-notification-component';

/**
 * Component responsible for rendering a warning notification.
 */
export interface IWarningNotificationProps extends INotificationProps<WarningNotification>, INotificationCloseButtonProps<WarningNotification> {}

/**
 * Component responsible for rendering a success notification.
 */
export class WarningNotificationComponent extends BaseNotificationComponent<WarningNotification, IWarningNotificationProps> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-warning';

    /**
     * Initializes the component with the given configuration.
     * @param {ISuccessNotificationProps} props Configuration of the component.
     */
    public constructor(props: IWarningNotificationProps) {
        super(props);
    }

    /**
     * Renders the given success notification.
     */
    public render(): React.ReactNode {
        return (
            <SystemNotificationComponent
                notification={this.props.notification}
                className={WarningNotificationComponent.className}
            />
        );
    }
}