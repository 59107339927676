import { Event } from '@msdyn365-commerce-modules/retail-actions';
import { BaseNotification } from './base-notification';
import { NotificationEvents } from './events';

/**
 * Specifies the timeouts in milliseconds used for the dismissing.
 */
export enum DismissTimeout {
    /**
     * Default timeout which should be used for most of the notification.
     */
    Default = 10000
}

/**
 * Represents a notification which can be automatically dismissed.
 * @remark Dismissing means automatically hiding the notification after some specific delay.
 */
export abstract class DismissibleNotification extends BaseNotification {
    /**
     * Shows that the notification is in progress of dismissing when dismissing animation is in progress.
     * @remark The process can be prevented by hovering on a notification.
     */
    public get isDismissing(): boolean {
        return this._isDismissing;
    }

    /**
     * Shows that the notification is fully dismissed and is hidden to user now.
     * The notification can be removed from the list and disposed.
     */
    public get isDismissed(): boolean {
        return this._isDismissed;
    }

    /**
     * Specifies the delay in milliseconds for the notification.
     * @remarks Using `DismissTimeout` is preferable.
     */
    public readonly autoDismissTimeout: DismissTimeout | number | null;

    /**
     * Specifies if the dismissing should be delayed (the timer should be reset) when user hovers on the notification.
     * True by default. Use false when notification should be dismissed regardless if user wants to interact with it or not (e.g. for limited time offers).
     */
    public readonly shouldResetOnHover: boolean;

    /**
     * Shows that the notification is in progress of dismissing when dismissing animation is in progress.
     * @remark The process can be prevented by hovering on a notification.
     */
    protected _isDismissing: boolean;

    /**
     * Shows that the notification is fully dismissed and is hidden to user now.
     * The notification can be removed from the list and disposed.
     */
    protected _isDismissed: boolean;

    /**
     * Initializes events and sets the delays for the dismissing.
     * @param {DismissTimeout | number} autoDismissTimeout The delay in milliseconds after which the notification should start play the hiding animation.
     * By default refers to `DismissTimeout.Default`.
     * Prefer using `DismissTimeout` than specifying the number.
     * @param {boolean} shouldResetOnHover Flag which specifies if the dismissing should be delayed (the timer should be reset) when user hovers on the notification.
     * True by default. Use false when notification should be dismissed regardless if user wants to interact with it or not (e.g. for limited time offers).
     */
    public constructor(autoDismissTimeout: DismissTimeout | number | null = DismissTimeout.Default, shouldResetOnHover: boolean = true) {
        if (autoDismissTimeout === null && shouldResetOnHover) {
            throw new Error('Invalid parameters passed for dismissible notification. shouldResetOnHover = true is incompatible with autoDismissTimeout = null.');
        }

        super();

        this.autoDismissTimeout = autoDismissTimeout;
        this.shouldResetOnHover = shouldResetOnHover;

        this._isDismissed = false;
        this._isDismissing = false;

        this.events.setValue(NotificationEvents.StartDismissing, new Event());
        this.events.setValue(NotificationEvents.PreventDismissing, new Event());
        this.events.setValue(NotificationEvents.FinishDismissing, new Event());
    }

    /**
     * Starts dismissing process by updating the `isDismissing` field and triggering the start dismissing event.
     */
    public startDismissing(): void {
        this._isDismissing = true;
        this.events.getValue(NotificationEvents.StartDismissing)?.trigger();
    }

    /**
     * Prevents dismissing process by updating the `isDismissing` field and triggering the prevent dismissing event.
     */
    public preventDismissing(): void {
        this._isDismissing = false;
        this.events.getValue(NotificationEvents.PreventDismissing)?.trigger();
    }

    /**
     * Finishes dismissing process by updating the `isDismissing` and `isDismissed` fields,
     * triggers the finish dismissing event,
     * removes the notification from the list as it should be hidden already.
     */
    public finishDismissing(): void {
        this._isDismissing = false;
        this._isDismissed = true;
        this.events.getValue(NotificationEvents.FinishDismissing)?.trigger();
        this.remove();
    }
}