// tslint:disable:no-any
// tslint:disable:no-duplicate-parameter-names

import { INotificationProps } from '../../notification-components';
import { BaseNotification } from './base-notification';

/**
 * Decorator which should be used to specify the react component class for a notification data instances.
 * @param {React.ComponentClass<PropsType, StateType>} componentClass The component class responsible for rendering all the data instances of this notification class.
 */
export const withNotificationComponent = <
    NotificationType extends BaseNotification,
    PropsType extends INotificationProps<NotificationType>,
    StateType extends {}>(
    componentClass: React.ComponentClass<PropsType, StateType>) => {

    return <T extends new(...args: any[]) => {}>(constructor: T) => {
        return class extends constructor {
            public readonly notificationComponentConstructor: React.ComponentClass<PropsType, StateType> = componentClass;
        };
    };
};

// tslint:enable:no-any
// tslint:enable:no-duplicate-parameter-names