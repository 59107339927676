import * as React from 'react';

import { BaseNotification } from '../../notifications-data-instances';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

/**
 * Displays footer of the notification.
 * Usually footer is used for extra actions.
 */
export class NotificationFooter<NotificationType extends BaseNotification> extends BaseNotificationComponent<NotificationType, INotificationElementProps<NotificationType>> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-footer';

    public constructor(props: INotificationElementProps<NotificationType>) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <div className={NotificationFooter.className}>
                {this.props.children}
            </div>
        );
    }
}