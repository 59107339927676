import * as React from 'react';

import { BaseNotification, IClosableNotification } from '../../notifications-data-instances';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

export interface INotificationCloseButtonState {
    isClosed: boolean;
}

export interface INotificationCloseButtonProps<NotificationType extends BaseNotification & IClosableNotification> extends INotificationElementProps<NotificationType> { }

/**
 * Represents a close button for the notification which triggers notification.close().
 */
export class NotificationCloseButton<NotificationType extends BaseNotification & IClosableNotification>
    extends BaseNotificationComponent<NotificationType, INotificationCloseButtonProps<NotificationType>, INotificationCloseButtonState> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-close';

    public constructor(props: INotificationCloseButtonProps<NotificationType>) {
        super(props);
        this._close = this._close.bind(this);
        this.state = {
            isClosed: false
        };
    }

    public render(): React.ReactNode {
        return (
            <button type='button' onClick={this._close} className={NotificationCloseButton.className} aria-label={this.props.notification.closeAriaLabel}/>
        );
    }

    private _close(): void {
        this.props.notification.close();
    }
}