import React, { ReactNode } from 'react';

import { BaseNotification } from '../../notifications-data-instances';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

/**
 * Represents the notification body which is displayed to the user.
 * It should include header, content, footer.
 */
export class NotificationInstance<NotificationType extends BaseNotification> extends BaseNotificationComponent<NotificationType, INotificationElementProps<NotificationType>> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-instance';

    public constructor(props: INotificationElementProps<NotificationType>) {
        super(props);
    }

    public render(): ReactNode {
        return (
            <div className={NotificationInstance.className}>
                {this.props.children}
            </div>
        );
    }
}