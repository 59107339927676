import { Dictionary, Event, Random } from '@msdyn365-commerce-modules/retail-actions';
import { INotificationProps } from '../../notification-components/base/base-notification-component';
import { NotificationEvents } from './events';

/**
 * Represents a react component class which renders a notification.
 */
export type NotificationComponentClass = React.ComponentClass<INotificationProps<BaseNotification>, {}>;

/**
 * Represents base functionality of a notification.
 */
export abstract class BaseNotification {
    /**
     * Notification events for the current notification.
     */
    public readonly events: Dictionary<NotificationEvents, Event>;

    /**
     * The local date set when the instance was created.
     */
    public readonly receivedDate: Date;

    /**
     * Unique identifier of the notification.
     * Represents a GUID that is automatically generated during the object initialization.
     */
    public readonly id: string;

    /**
     * The react class which is responsible for rendering the current instance of notification.
     * Should be set using @withNotificationComponent decorator.
     */
    public readonly notificationComponentConstructor!: NotificationComponentClass;

    /**
     * Specifies if the notification instance was removed from a notification list.
     */
    public get isRemoved(): boolean {
        return this._isRemoved;
    }

    /**
     * Specifies if the notification instance was removed from a notification list.
     */
    protected _isRemoved: boolean;

    /**
     * Initializes a notification with base information such as id, events, date when initialized.
     */
    public constructor() {
        this.receivedDate = new Date();
        this._isRemoved = false;
        this.events = new Dictionary({ key: NotificationEvents.RemovedFromList, value: new Event() });
        this.id = `Notification-${Random.Guid.generateGuid()}`;
    }

    /**
     * Updates the notification instance when it's removed from a notification list.
     * Should be called from a notification component once the notification can be disposed.
     */
    public remove(): void {
        this._isRemoved = true;
        this.events.getValue(NotificationEvents.RemovedFromList)?.trigger();
        this._dispose();
    }

    /**
     * Unsubscribes given instance from all the events for the notification.
     * @param {string} instanceId Unique id which represents the event handler.
     * @remarks Does nothing in case the instance was not subscribed to any event.
     */
    public unsubscribeFromEvents(instanceId: string): void {
        this.events.getValues().forEach(event => event.unsubscribe(instanceId));
    }

    /**
     * Disposes the events by unsubscribing all members.
     */
    protected _dispose(): void {
        this.events.getValues().forEach(event => event.unsubscribeAll());
        this.events.clear();
    }
}