import * as React from 'react';

import { ArrayExtensions, StringExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { RichTextComponent } from '@msdyn365-commerce/core';

import { BaseNotification, IDescriptiveNotification } from '../../notifications-data-instances';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

/**
 * Displays notification description lines.
 */
export class NotificationDescription<NotificationType extends BaseNotification & IDescriptiveNotification> extends BaseNotificationComponent<NotificationType, INotificationElementProps<NotificationType>> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-description';

    public constructor(props: INotificationElementProps<NotificationType>) {
        super(props);
    }

    public render(): React.ReactNode {
        if (!ArrayExtensions.hasElements(this.props.notification.descriptionLines)) {
            return null;
        }

        return (
            <div className={NotificationDescription.className}>
                {
                    this.props.notification.descriptionLines.map((line, index) => {
                        if (StringExtensions.isNullOrWhitespace(line.toString())) {
                            return null;
                        }
                        return (
                            <RichTextComponent
                                key={index}
                                text={line}
                                className={`${NotificationDescription.className}__line`}
                            />
                        );
                    })
                }
                {this.props.children}
            </div>
        );
    }
}