import React from 'react';

import { BaseNotification } from '../../notifications-data-instances';

/**
 * Default props for a component responsible for rendering the given notification.
 * @remark Modify carefully as it's initialized by the notifications manager to provide dynamic rendering.
 */
export interface INotificationProps<NotificationType extends BaseNotification> {
    notification: NotificationType;
}

/**
 * Default props for a component responsible for rendering some part of notification, but not the whole notification (e.g. @see NotificationTitle).
 */
export interface INotificationElementProps<NotificationType extends BaseNotification> extends INotificationProps<NotificationType> {
}

/**
 * Represents a base class which renders a notification or a part of notification.
 */
export abstract class BaseNotificationComponent<
    NotificationType extends BaseNotification,
    PropsType extends INotificationProps<NotificationType> = INotificationProps<NotificationType>,
    StateType = {}> extends React.Component<PropsType, StateType> {

    public constructor(props: PropsType) {
        super(props);
    }

    public abstract render(): React.ReactNode;
}