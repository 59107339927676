import React from 'react';

import { ErrorNotification } from '../../notifications-data-instances/system-notifications';
import { INotificationCloseButtonProps } from '../base';
import { BaseNotificationComponent, INotificationProps } from '../base/base-notification-component';
import { SystemNotificationComponent } from './system-notification-component';

/**
 * Props for error notification.
 */
export interface IErrorNotificationProps extends INotificationProps<ErrorNotification>, INotificationCloseButtonProps<ErrorNotification> {}

/**
 * Component responsible for rendering an error notification.
 */
export class ErrorNotificationComponent extends BaseNotificationComponent<ErrorNotification, IErrorNotificationProps> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-error';

    /**
     * Initializes the component with the given configuration.
     * @param {IErrorNotificationProps} props Configuration of the component.
     */
    public constructor(props: IErrorNotificationProps) {
        super(props);
    }

    /**
     * Renders the given error notification.
     */
    public render(): React.ReactNode {
        return (
            <SystemNotificationComponent
                notification={this.props.notification}
                className={ErrorNotificationComponent.className}
            />
        );
    }
}