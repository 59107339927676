/**
 * Specifies different feeds for the notification lists.
 * Denotes the purpose of a notification list and what kind of notifications should be passed to the list.
 * By default is global (the global notification lists which is used to show most notifications).
 */
export enum NotificationFeeds {
    Global = 'global'
}

/**
 * Represents a type of each notification feed. Is used for notification lists components.
 * @see NotificationFeeds
 */
export type NotificationFeedType = string;