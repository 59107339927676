import * as React from 'react';

import { RichTextComponent } from '@msdyn365-commerce/core';

import { BaseNotification, ITitledNotification } from '../../notifications-data-instances';
import { BaseNotificationComponent, INotificationElementProps } from './base-notification-component';

/**
 * Props for the notification title component.
 */
export interface INotificationTitleProps<NotificationType extends BaseNotification & ITitledNotification> extends INotificationElementProps<NotificationType> {
    /**
     * Represents a node which should be displayed before the title. Optional field.
     */
    preChildren?: React.ReactNode;

    /**
     * Represents a node which should be displayed after the title. Optional field.
     */
    postChildren?: React.ReactNode;
}

/**
 * Renders notification title.
 */
export class NotificationTitle<NotificationType extends BaseNotification & ITitledNotification> extends BaseNotificationComponent<NotificationType, INotificationTitleProps<NotificationType>> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-title';

    public constructor(props: INotificationTitleProps<NotificationType>) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <div className={NotificationTitle.className}>
                {this.props.preChildren}
                <RichTextComponent
                    text={this.props.notification.title}
                />
                {this.props.postChildren}
            </div>
        );
    }
}