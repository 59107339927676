import { Spinner } from 'office-ui-fabric-react';
import React from 'react';

import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';

import { LoadingNotification } from '../../notifications-data-instances/system-notifications';
import { DismissibleNotificationWrapper, NotificationContent, NotificationDescription, NotificationHeader, NotificationInstance, NotificationTitle } from '../base';
import { BaseNotificationComponent, INotificationProps } from '../base/base-notification-component';

/**
 * Props for loading notification.
 */
export interface ILoadingNotificationProps extends INotificationProps<LoadingNotification> {}

/**
 * Component responsible for rendering a loading notification.
 */
export class LoadingNotificationComponent extends BaseNotificationComponent<LoadingNotification, ILoadingNotificationProps> {
    /**
     * The class for the styles.
     */
    public static readonly className: string = 'msc-notification-loading';

    /**
     * Initializes the component with the given configuration.
     * @param {ILoadingNotificationProps} props Configuration of the component.
     */
    public constructor(props: ILoadingNotificationProps) {
        super(props);
    }

    /**
     * Renders the given notification.
     */
    public render(): React.ReactNode {
        return (
            <div className={LoadingNotificationComponent.className}>
                <DismissibleNotificationWrapper
                    notification={this.props.notification}
                >
                    <NotificationInstance
                        notification={this.props.notification}
                    >
                        <NotificationHeader
                            notification={this.props.notification}
                        >
                            <NotificationTitle
                                notification={this.props.notification}
                                preChildren={<Spinner />}
                            />
                        </NotificationHeader>
                        {
                            ArrayExtensions.hasElements(this.props.notification.descriptionLines) ? (
                                <NotificationContent
                                    notification={this.props.notification}
                                >
                                    <NotificationDescription
                                        notification={this.props.notification}
                                    />
                                </NotificationContent>
                            ) : null
                        }
                    </NotificationInstance>
                </DismissibleNotificationWrapper>
            </div>
        );
    }
}