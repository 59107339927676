import { ArrayExtensions, Event } from '@msdyn365-commerce-modules/retail-actions';
import { INotificationComponentType } from '../notification-components/notification-component-type';
import { NotificationComponentsListsManager } from '../notification-components/notification-components-lists-manager';
import { BaseNotification, NotificationComponentClass } from '../notifications-data-instances';
import { NotificationFeeds } from './notification-feeds';

/**
 * Notification manager is a singleton.
 * It is responsible for receiving a notification,
 * retrieving notification component class,
 * and passing the component class to the notification components lists
 * @see NotificationComponentsList which renders the list of notifications.
 */
export class NotificationsManager {
    /**
     *  Singleton global instance of the notification manager.
     */
    private static _applicationNotificationManager?: NotificationsManager;

    /**
     * Event which is triggered after the notification was added to the lists.
     * @see addNotification method.
     * @remark is not triggered in case of any errors.
     */
    public readonly notificationAddedEvent: Event;

    /**
     * Retrieves global singleton instance of the notifications manager.
     * @returns {NotificationsManager} Instance of the manager which is used throughout the whole application.
     */
    public static instance(): NotificationsManager {
        if (!this._applicationNotificationManager) {
            this._applicationNotificationManager = new NotificationsManager();
        }

        return this._applicationNotificationManager;
    }

    /**
     * Adds given notifications to the notification component lists.
     * It receives a notification, retrieves notification component class,
     * and passes the component class to the notification components lists.
     * @see NotificationComponentsList which renders the list of notifications.
     * Then it triggers the notification added event.
     * @remark In case of any errors, suppress the error and write it in the console.
     * @param {NotificationType} notification Notification instance with the data.
     * @param {NotificationFeeds} feed The configuration of the notification component list. By default is global.
     */
    public addNotification<NotificationType extends BaseNotification>(
        notification: NotificationType, feed: NotificationFeeds = NotificationFeeds.Global): void {

        const component = this._getNotificationComponent(notification);

        try {
            const componentType: INotificationComponentType = {
                componentClass: component,
                props: {
                    notification: notification
                }
            };
            const lists = NotificationComponentsListsManager.getNotificationLists(feed);

            if (!ArrayExtensions.hasElements(lists)) {
                notification.remove();
                return;
            }

            lists.forEach(
                componentManager => componentManager.addNotificationComponent(componentType));

            this.notificationAddedEvent.trigger();
        } catch (error) {
            console.error('Error while adding a notification');
            console.error(notification);
            console.error(error);
        }
    }

    /**
     * Initializes the instance with the notification event.
     */
    private constructor() {
        this.notificationAddedEvent = new Event();
    }

    /**
     * Retrieves the component responsible for rendering the given notification.
     * @param {NotificationType} notification The data instance of the notification which should be added.
     * @returns {NotificationComponentClass} The react class which should render the given notification.
     */
    private _getNotificationComponent<NotificationType extends BaseNotification>(notification: NotificationType): NotificationComponentClass {
        const componentClass = notification.notificationComponentConstructor;
        if (!componentClass) {
            const notificationName = (<Object>notification).constructor.name;
            throw new Error(`No notification component found for the given notification ${notificationName}. ` +
                'Please use \'withNotificationComponent\' decorator on the notification and specify the type to use');
        }

        return componentClass;
    }
}